
import { defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Header from '../components/header/Header.vue';
import Result from '../components/search-page/Result.vue';
import Anime from '../types/Anime';

export default defineComponent({
  components: { Header, Result },
  setup() {
    const route = useRoute();
    const searchValue = ref(route.params.searchQuery);
    const anime = ref<Anime[] | null>(null);
    const searchAnime = async () => {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BACKEND_URL}/search/${searchValue.value}`
        );
        if (!response.ok) {
          throw Error('No data available');
        }
        const json = await response.json();
        const jsonObj = await JSON.parse(json);
        anime.value = jsonObj.data;
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };
    searchAnime();

    watch(route, () => {
      searchValue.value = route.params.searchQuery;
      searchAnime();
    });

    const toggleSynopsis = () => {
      const shortSynopsis = document.querySelector('.short-synopsis') || null;
      const fullSynopsis = document.querySelector('.full-synopsis') || null;

      if (fullSynopsis && shortSynopsis && fullSynopsis.classList.contains('hidden')) {
        shortSynopsis.classList.add('hidden');
        fullSynopsis.classList.remove('hidden');
      } else if (fullSynopsis && shortSynopsis && shortSynopsis.classList.contains('hidden')) {
        shortSynopsis.classList.remove('hidden');
        fullSynopsis.classList.add('hidden');
      }
    };

    return { anime, toggleSynopsis };
  }
});
