
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: ['anime'],
  setup(props) {
    const toggleSynopsis = () => {
      const shortSynopsis = document.querySelector(`.short-synopsis${props.anime.node.id}`) || null;
      const fullSynopsis = document.querySelector(`.full-synopsis${props.anime.node.id}`) || null;

      if (fullSynopsis && shortSynopsis && fullSynopsis.classList.contains('hidden')) {
        shortSynopsis.classList.add('hidden');
        fullSynopsis.classList.remove('hidden');
      } else if (fullSynopsis && shortSynopsis && shortSynopsis.classList.contains('hidden')) {
        shortSynopsis.classList.remove('hidden');
        fullSynopsis.classList.add('hidden');
      }
    };

    return { toggleSynopsis };
  }
});
